import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// create a SW using sw.js
const registerSW = async () => {
  // check the support for SW
  try {
    if ("serviceWorker" in navigator) {
      await navigator.serviceWorker.register("/sw.js");

      // wait for the SW to be ready
      const activatedSW = await navigator.serviceWorker.ready;
      // register a sync manager with tag =my-sync with it
      await activatedSW.sync.register("my-sync");

      console.log("SW supported and registered!");
    } else {
      console.log("Sorry! SWs are not supported!");
    }
  } catch (error) {
    console.log("Error in registerSW", error);
  }
};
registerSW();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
